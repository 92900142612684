import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import styled from 'styled-components'
// import { Card } from 'components/styled/card'
import { ShadowText } from 'components/styled/typography'
import ArrowIcon from 'svgs/arrow_top_right.svg'
import LongIcon from 'svgs/newDesigns/long-arrow.svg'
import { SCREEN } from 'styles/screens'
// import { config } from '../../config'
import { getTranslation } from '../../data/dictionary'
import GirlIllustrationOne from '../../svgs/newDesigns/Illustration_1_test.svg'
import GirlIllustrationTwo from '../../svgs/newDesigns/Illustration_2_test.svg'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  min-height: 800px;
  margin-bottom: 60px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
    min-height: 600px;
  }
  .column-title {
    font-weight: 500;
    margin-bottom: 0;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1520px;
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: wrap;

  > div {
    margin-bottom: 20px;
  }

  h6 {
    font-weight: 400;
    line-height: 1.8;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
    flex-direction: column;
  }
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -15px;
  margin-right: -15px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
    margin-right: 0;
  }
`

const ColumnFour = styled.div`
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-child {
    justify-content: end !important;
  }
  &:last-child {
    justify-content: start !important;
  }
  &.d-desktop-none {
    display: none;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    svg {
      max-width: 500px !important;
      height: auto !important;
    }
    &.d-desktop-none {
      margin-top: 30px;
      display: flex;
    }
    &:first-child {
      justify-content: center !important;
    }
    &:nth-child(2):not(.d-desktop-none) {
      display: none;
    }
    &:last-child {
      display: none;
    }
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    svg {
      max-width: 300px !important;
      height: auto !important;
    }
  }
`

const ColumnHalf = styled.div`
  flex: 0 0 48.2%;
  max-width: 48.2%;
  padding-left: 15px;
  padding-right: 15px;

  &:last-child {
    margin-left: auto;
  }
  &:first-child {
    ul {
      li {
        position: relative;
        div.long-icon {
          position: absolute;
          right: 0;
          transform: translateX(100%);
        }
      }
    }
  }

  > ul {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    li {
      border: 1px solid #9b9b9b;
      border-radius: 20px;
      height: 130px;

      display: flex;
      align-items: center;
      line-height: 35px;
      padding: 13px 40px;
      font-size: 23px;
      margin-top: 26px;
      icon {
        margin-right: 15px;
        margin-top: 10px;
      }
      > div {
        display: flex;
      }
      div.d-flex.icon {
        margin-right: 1rem;
        margin-top: 11px;

        > svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    &:first-child {
      ul {
        li {
          position: relative;
          div.long-icon {
            display: none;
          }
        }
      }
    }
    > ul li {
      > div {
        margin-left: 1rem;
      }
    }

    &:last-child {
      margin-left: 0;
    }

    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    ul li {
      font-size: 16px;
    }
  }
`

const WhoIsItForV2 = () => {
  return (
    <Container>
      <FixedContainer>
        <ShadowText highlight>
          <h3>
            {getTranslation('who_is_it_for_heading', 'Who is it for?', false)}
          </h3>
        </ShadowText>
        <DescriptionContainer>
          {/* <Row>
            <ColumnFour>
              <GirlIllustrationOne />
            </ColumnFour>
            <ColumnFour>
              <LongIcon />
            </ColumnFour>
            <ColumnFour>
              <GirlIllustrationTwo />
            </ColumnFour>
          </Row> */}
          <Row>
            <ColumnHalf>
              <h6 className="column-title">You are:</h6>
              <ul>
                <li className="mt-5px">
                  <div>
                    <div className="d-flex icon">
                      <ArrowIcon />
                    </div>
                    Professionals with 1+ years of experience, currently working
                    as specialists in the technology field
                  </div>
                  <div className="long-icon">
                    <LongIcon />
                  </div>
                </li>
                <li className="mt-5px">
                  <div>
                    <div className="d-flex icon">
                      <ArrowIcon />
                    </div>
                    Ambitiouse, curious, hungry for career development and
                    getting new experiences
                  </div>
                  <div className="long-icon">
                    <LongIcon />
                  </div>
                </li>
                <li className="mt-5px">
                  <div>
                    <div className="d-flex icon">
                      <ArrowIcon />
                    </div>
                    Ready for hard work and understand that success does not
                    come in a day
                  </div>
                  <div className="long-icon">
                    <LongIcon />
                  </div>
                </li>
                <li className="mt-5px">
                  <div>
                    <div className="d-flex icon">
                      <ArrowIcon />
                    </div>
                    Finding problem-solving challenging, and regularly face
                    prioritization challenges
                  </div>
                  <div className="long-icon">
                    <LongIcon />
                  </div>
                </li>
              </ul>
            </ColumnHalf>
            <ColumnFour className="d-desktop-none">
              <StaticImage
                src="../../svgs/newDesigns/long-arrow-vertical.svg"
                alt="workspace"
                layout="constrained"
                className="mt-auto mb-auto d-tablet-none"
                placeholder="tracedSVG"
              />
            </ColumnFour>
            <ColumnFour className="d-desktop-none">
              <GirlIllustrationTwo />
            </ColumnFour>
            <ColumnHalf>
              <h6 className="column-title">You want to:</h6>
              <ul>
                <li className="mt-5px">
                  <div>
                    <div className="d-flex icon">
                      <ArrowIcon />
                    </div>
                    Deliver products that are solving real user problems
                  </div>
                </li>
                <li className="mt-5px">
                  <div>
                    <div className="d-flex icon">
                      <ArrowIcon />
                    </div>
                    Grow your professional network and make it international
                  </div>
                </li>
                <li className="mt-5px">
                  <div>
                    <div className="d-flex icon">
                      <ArrowIcon />
                    </div>
                    Be part of a high-level decision-making process shaping the
                    product
                  </div>
                </li>
                <li className="mt-5px">
                  <div className="d-flex align-items-start">
                    <div className="d-flex icon">
                      <ArrowIcon />
                    </div>
                    Understand the process of creating a Product from scratch
                    and the work with specialist from different fields
                  </div>
                </li>
              </ul>
            </ColumnHalf>
          </Row>
        </DescriptionContainer>
      </FixedContainer>
    </Container>
  )
}

export default WhoIsItForV2

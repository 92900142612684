import * as React from 'react'
import styled from 'styled-components'

import Layout from 'layouts/main'
import HeroSection from 'components/home/heroSection'
import ProgramSection from 'components/home/programSection'
import ValuesSection from 'components/home/valuesSection'
// import BecomeTrusteeSection from 'components/home/becomeTrusteeSection'
// import WhoIsItFor from 'components/home/whoIsItFor'
import WhoIsItForV2 from 'components/home/whoIsItForV2'
// import LearningFrom from 'components/home/LearningFrom'
import HowDoesItWork from 'components/home/HowDoesItWork'
// import CalendarSection from 'components/home/calendarSection'
// import CoursesSection from 'components/home/coursesSection'
import MentorsSection from 'components/home/MentorsSection'
import StudentSection from 'components/home/studentsSection'
import ProcessSection from 'components/home/processSection'
import WallOfLoveSection from 'components/home/wallOfLoveSection'
import CoursesSection from 'components/home/coursesSection'


const Container = styled.div`
  overflow: hidden;
`

const IndexPage = ({ pageContext }) => {
  const { programs, showModal } = pageContext
  return (
    <div className="Homepage">
      <Layout title="Home">
        {({ setMobileCTAVisible, setRegModalIsOpen }) => (
          <Container>
            <HeroSection
              showModal={showModal}
              programs={programs}
              setMobileCTAVisible={setMobileCTAVisible}
              handleModal={setRegModalIsOpen}
            />
            <StudentSection />
            <CoursesSection programs={programs} />
            <HowDoesItWork/>
            <ProcessSection />
            <WallOfLoveSection />
            {/* <ProgramSection /> */}
            {/* <WhoIsItFor /> */}
            {/* <WhoIsItForV2 /> */}
            {/* <LearningFrom programs={programs}/> */}
            {/* <ValuesSection /> */}
            {/* <BecomeTrusteeSection /> */}

            {/* <CalendarSection /> */}
            {/* <CoursesSection programs={programs} /> */}
            <MentorsSection handleModal={setRegModalIsOpen} />
          </Container>
        )}
      </Layout>
    </div>

  )
}

export default IndexPage

import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import styled from 'styled-components'

// COMPONENTS
import { ShadowText } from 'components/styled/typography'
// import { FixedContainer } from '../styled/containers'

// ASSETS
import { SCREEN } from 'styles/screens'
import { getTranslation } from '../../data/dictionary'
import RenderHtml from '../shared/renderHtml'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;
  min-height: 800px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`
const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1520px;

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex-direction: column-reverse;
  }
`

const DescriptionContainer = styled.div`
  flex: 1 1 49%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > h5 {
    margin-top: 0;
    // max-width: 510px;
    font-size: 23px;
    font-weight: 400;
    line-height: 1.7;
  }
  > button {
    margin-right: 12px;
    > svg {
      margin-left: 14px;
    }
  }
  small {
    max-width: 400px;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
    h5 {
      font-size: 18px;
      max-width: 100%;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    order: 2;
    > p:first-child {
      margin-top: 0;
    }
    small {
      max-width: 270px;
    }
  }
`

const ImageContainer = styled.div`
  margin-top: 62px;
  flex: 1 1 49%;
  margin-left: 1%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
  > div {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: -20px;
      left: -22px;
      height: 65%;
      width: 65%;
      border: 3px solid var(--primary);
      z-index: -1;
    }
    &:after {
      content: '';
      position: absolute;
      right: -22px;
      bottom: -15px;
      height: 65%;
      width: 65%;
      background-color: var(--secondary);
      z-index: -1;
    }
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    order: -1;
    margin: 24px;
    margin-bottom: 56px;
    padding-right: 0;
  }
`

const ProgramSection = () => (
  <Container>
    <FixedContainer>
      <DescriptionContainer>
        <ShadowText highlight>
          <h3>
            {getTranslation('homepage_program_heading', 'The program', false)}
          </h3>
        </ShadowText>
        <h5>
          <RenderHtml
            html={getTranslation(
              'homepage_program_description',
              'An immersive digital program, BuildUp creates one-of-a-kind opportunities for learning using multidisciplinary teamwork on real-world products.\n',
              false,
            )}
            tag={'div'}
          />
        </h5>
      </DescriptionContainer>
      <ImageContainer>
        <div>
          <StaticImage
            src="../../images/programSectionImage.jpg"
            alt="workspace"
            layout="constrained"
            width={540}
            height={480}
            placeholder="tracedSVG"
          />
        </div>
      </ImageContainer>
    </FixedContainer>
  </Container>
)

export default ProgramSection

import * as React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: inline-block;
  max-width: ${({ width }) => (width ? width : 500)}px;
  max-height: ${({ height }) => (height ? height : 500)}px;

  > img {
    display: block;
    width: 100%;
    max-width: ${({ width }) => (width ? `${width}px` : '100%')};
    max-height: ${({ height }) => (height ? `${height}px` : '100%')};
    object-fit: ${({ fit }) => (fit ? fit : 'cover')};
    object-position: ${({ position }) => (position ? position : 'center')};
  }
`

export const Picture = ({
  src = '',
  alt = 'image',
  width,
  height,
  position,
  fit,
}) => (
  <Container width={width} height={height} position={position} fit={fit}>
    <img src={src} alt={alt} height={height} width={width} />
  </Container>
)
